<template>
  <div class="blockInfo">
    <div class="blockTitle" @click="toggleDropdown">{{ title }}</div>
    <div class="dropdown" :class="{ disabled: disabled }">
      <div class="dropdown-display" @click="toggleDropdown" ref="dropdownContainer">
        <span v-if="!valueSelected.length">{{ $t('select_tpp_options') }}</span>
        <span v-for="(item, index) in valueSelected" :key="index" class="selected-item">
          {{ getLabel(item, true) }}
          <em class="remove" @click.stop="removeSelected(item)">x</em>
        </span>
        <em class="arrow" :class="{ open: dropdownOpen }"></em>
      </div>

      <div v-if="dropdownOpen" class="dropdown-options">
        <label v-for="item in values" :key="item._key || item" class="dropdown-item">
          <input
            type="checkbox"
            :value="item._key || item"
            :checked="valueSelected.includes(item._key || item)"
            @change="updateSelection(item._key || item)"
            :disabled="disabled"
          />
          <span style="margin-left: 8px;">{{ getLabel(item) }}</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BlockInfoMultipleSelect",
  props: {
    title: String,
    selected: Array,
    values: Array,
    disabled: Boolean,
  },
  data() {
    return {
      dropdownOpen: false,
      valueSelected: this.selected || [],
    };
  },
  methods: {
    toggleDropdown() {
      if (!this.disabled) {
        this.dropdownOpen = !this.dropdownOpen;
      }
    },
    closeDropdown() {
      this.dropdownOpen = false;
    },
    getLabel(item, value=false) {
      if (!value) return `${item._key} - ${item.value}` || item;
      else return item._key || item;
    },
    removeSelected(item) {
      this.valueSelected = this.valueSelected.filter(selected => selected !== item);
      this.emitSelectionChange();
    },
    updateSelection(item) {
      if (this.valueSelected.includes(item)) {
        this.valueSelected = this.valueSelected.filter(selected => selected !== item);
      } else {
        this.valueSelected.push(item);
      }
      this.emitSelectionChange();
    },
    emitSelectionChange() {
      // Emitimos el cambio de selección hacia el componente padre
      this.$emit("change-value", [...this.valueSelected]);
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.closeDropdown();
      }
    },
  },
  watch: {
    selected(newVal) {
      this.valueSelected = newVal || [];
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped>
/* Estilos para dropdown */
.blockInfo {
  padding-top: 0.5em;
  padding-bottom: 1em;
  margin-bottom: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.1);
}
.blockTitle {
  width: 50%;
  cursor: pointer;
}
.dropdown {
  width: 100%;
  position: relative;
  cursor: pointer;
}
.dropdown-display {
  padding: 0.5em;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.3em;
  background-color: #f9f9f9;
  transition: all 0.3s ease;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.dropdown-display:hover {
  border-color: #248f48;
}
.dropdown-display .selected-item {
  background-color: #26994d;
  color: white;
  padding: 0.2em 0.5em;
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
}
.dropdown-display .remove {
  margin-left: 0.3em;
  cursor: pointer;
  font-weight: bold;
}
.dropdown .dropdown-options {
  position: absolute;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  margin-top: 0.5em;
  z-index: 1000;
}
.dropdown-item {
  padding: 0.5em;
  display: flex;
  align-items: center;
  transition: background-color 0.2s;
}
.dropdown-item:hover {
  background-color: #f1f1f1;
}
.arrow {
  margin-left: auto;
  transition: transform 0.3s;
}
.arrow.open {
  transform: rotate(180deg);
}
</style>
